.login .container #CustomerLoginForm {
  max-width: 370px;
  margin: 0 auto;
}

.login a[href="/account/register"] {
  display: flex;
  max-width: 370px;
  margin: 0 auto;
  /* THIS WILL DISABLE BUTTON ON LOGIN PAGE
  display: none;
  */
}

@media (min-width: 768px) {
  .price {
    white-space: nowrap;
  }  
}

.price::after,
.wcp-cart-total::after {
  content: "exkl. MwSt.";
  font-size: 12px;
  font-weight: 300;
  margin-left: 4px;
  display: inline-block;
}

.wcp-cart-total {
  display: block;
}

.cart__items .price::after {
  margin-left: 0!important;
  display: block;
}


.price--sale::before {
  content: "Dein Preis:";
  display: block;
  font-size: .75rem;
  font-weight: 200;
  margin-bottom: 4px;
}

.header__line-additional {
  display: none !important;
}

.header__btn-account,
.header__btn-services {
  display: flex !important;
}


.breadcrumbs ul li {
  font-size: 13px;
}

.breadcrumbs ul li:last-child {
  font-weight: 900;
}



.header__tape {
  font-size: 13px;
  line-height: 1.2;
}



.header__line-info > div > div {
  display: block;
  width: 100%;
}

.usp-icons {
  display: block;
  
}

.usp-icons .usp-icons-item {
  display: block;
  margin: 4px 0;
}

@media (min-width: 768px) {
  .usp-icons {
    display: flex;
    padding: 6px 0;
    align-items: center;
    justify-content: space-between;
  }
  
  .usp-icons .usp-icons-item {
    margin: 0;
    padding-top: 4px;
  }
}



.footer__subscription .btn {
  background-color: var(--button-bg) !important;
  border-color: var(--button-bd) !important;
  white-space: nowrap;
}

.footer__subscription .btn:hover {
  background-color: var(--button-h-bg) !important;
  border-color: var(--button-h-bd) !important;
  color: var(--button-h-c) !important;
}


.footer__subscription {
  position: relative;
  padding: 2rem 0;
}

.footer__subscription::before {
  content: "";
  position: absolute;
  background: rgb(229, 239, 220);
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX( -50%);
  width: 100vw;
}



.blackroll-dropdown-menu {
  display: none;
  position: absolute;
  background: white;
  box-shadow: 1px 1px 6px rgba(0,0,0,0.1);
  padding: 0.5rem .25rem;
  top: 100%;
  margin-top: -18px;
  z-index: 50;
}

.blackroll-dropdown-menu li a {
  display: block;
  color: black;
  padding: .25rem 1.5rem;
}


.blackroll-dropdown-menu li a:hover,
.blackroll-dropdown-menu li a:focus {
  color: var(--theme-link-c);
}

.blackroll-dropdown-menu .blackroll-divider {
  margin: 3px 0;
  background: #eee;
  height: 1px;
}


.chContent-Body-Page-Customer-Name {
  white-space: nowrap;
}

.chEditAddress:before {
  display: none !important;
}


// Disable Email Form Clicks with CSS
#chPersonalInfoForm input[name="email"] {
  pointer-events: none;
}

.customerHub input[disabled] {
  background: #eee !important;
  color: #444 !important;
}